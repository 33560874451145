import axios from '../../mixins/axiosInstance'
/*import qs from 'qs';*/

const state = () => ({
    listOfPositions:[]
})
const getters = {}

const actions = {
    async searchByGroup({rootState,commit,dispatch},value){
        if(value){
            commit('Groups/updateOneDropdownGroup',value,{root:true})
            let data=await axios.instance.get('/gps?method=getGpsInfo&groupId='+rootState.Groups.dropdownGroup,{withCredentials: true, crossDomain: true})
            if(data.data.data.userGpsInfoList){
                commit('setListOfPositions',data.data.data.userGpsInfoList)
            }else{
                commit('setListOfPositions',[])
            }
        }else{
            dispatch('getPositions')
        }
        
    },
    async getPositions({commit}){
        let data=await axios.instance.get('/gps?method=getGpsInfo',{withCredentials: true, crossDomain: true})
        commit('setListOfPositions',data.data.data.userGpsInfoList)
    }
}
const mutations = {
    async setListOfPositions(state,value){
        state.listOfPositions=value.map((x)=>{
            return {userName:x.userName,position:{lat:parseFloat(x.lat),lng:parseFloat(x.lng)}}
        })
    }

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}