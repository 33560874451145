import axios from 'axios';

const instance = axios.create({
    baseURL: 'https://realptt.com/ptt',
    timeout: 0,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded'},
    withCredentials: true,
    SameSite:'Strict'
});

export default{instance}