import Vue from 'vue'
import VueRouter from 'vue-router'
import verifyAuth from '../mixins/verifyAuth'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: async () => {
      if(await verifyAuth.verifyAuth()){
        return import('../views/Dashboard.vue')
      }
      else{
        return import('../views/Login.vue');
      }
    }
  },
  {
    path: '/Users',
    name: 'Users',
    component: async () => {
      if(await verifyAuth.verifyAuth()){
        return import('../views/Home.vue')
      }
      else{
        return import('../views/Login.vue');
      }
    }
  },
  {
    path: '/Billing',
    name: 'billing',
    component: async () => {
      if(await verifyAuth.verifyAuth()){
        return import('../views/Billing.vue')
      }
      else{
        return import('../views/Login.vue');
      }
    }
  },
  {
    path: '/Groups',
    name: 'groups',
    component: async () => {
      if(await verifyAuth.verifyAuth()){
        return import('../views/Groups.vue')
      }
      else{
        return import('../views/Login.vue');
      }
    }
  },
  {
    path: '/Maps',
    name: 'maps',
    component: async () => {
      if(await verifyAuth.verifyAuth()){
        return import('../views/Maps.vue')
      }
      else{
        return import('../views/Login.vue');
      }
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router
