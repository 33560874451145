import axios from '../../mixins/axiosInstance.js';

const state=()=>({
    billingTable: {
        search: '',
        loading: true,
        billings: [],
        headers: [
            {
                text: "ID",
                sortable: false,
                value: "bill_id",
            },
            {
                text: "Fecha de creación",
                sortable: false,
                value: "create_time",
            },
            {
                text: "Fecha de fin",
                sortable: false,
                value: "end_time",
            },
            {
                text: "Fecha de inicio",
                sortable: false,
                value: "start_time"
            },
            {
                text: "Licencia antigua",
                sortable: false,
                value: "old_bill",
            },
            {
                text: "Privilegios",
                sortable: false,
                value: "privilege"
            },
            {
                text: "Estado",
                sortable: false,
                value: "status"
            },
            /*{
                text: "Acciones",
                sortable: false,
                value: "acciones",
            },*/
        ],
    },
    dropdownBills:[],
    dropdownBill:null,
    dialogActivateBill:false
})

const getters ={}

const actions ={
    async getDropdownBills({dispatch,commit}){
        try {
            let data=await axios.instance.get('/bill?method=get&status=1&limit=100')
            if(data.data.data.total > 100){
                try {
                    var length = data.data.data.total / 100
                    if (length > Math.round(length)) {
                        length = Math.round(length) + 1
                    } else {
                        length = Math.round(length)
                    }
                    var arr = [];
                    for (let index = 0; index < length; index++) {
                        let response2 = await axios.instance.get('/bill?method=get&limit=100&page=' + index)
                        arr = arr.concat(response2.data.data.bills)
                    }
                    let x=arr.filter(val=>val.old_bill===0)
                    await commit('setDropdownBills',x)
                } catch (error) {
                    console.log(error)
                }
            }
            else{
                let data=await axios.instance.get('/bill?method=get&status=1&limit=100')
                let x=data.data.data.bills.filter(val=>val.old_bill===0)
                await commit('setDropdownBills',x)
            }
        } catch (error) {
            if(error){
                await dispatch('Snackbars/setSnackbars', {
                    type:'error',
                    text: 'Ocurrió un error al obtener las licencias',
                  }, { root: true })
            }
        }
    },
    async getTableBilling({ commit, dispatch }){
        try {
            let data=await axios.instance.get('/bill?method=get')
            console.log(data.data.data.bills)
            commit('setBillingTable',data.data.data.bills);
            commit('closeTableLoader');
        } catch (error) {
            if(error){
                dispatch('Snackbars/setSnackbars', {
                    type:'error',
                    text: 'Ocurrió un error al obtener las licencias',
                  }, { root: true })
            }
        }
    },
    async getDropdownInactiveBills({commit}){
        let data=await  axios.instance.get('/bill?method=get&status=0')
        commit('setDropdownInactiveBills',data.data.data.bills)
    },
    async activateBill({commit,state,dispatch}){
        await  axios.instance.get('/bill?method=active&bill_id='+state.dropdownBill.billID+'&number='+state.dropdownBill.remainNumber)
         commit('closeDialogActivateBill')
         commit('Users/openDialogAddUser',null,{root:true})
         commit('setDropdownBills',[])
         dispatch('getDropdownBills')
    },
    async openDialogActivateBill({commit,dispatch})
    {
         commit('openDialogActivateBill')
         dispatch('getDropdownInactiveBills')
         commit('Users/closeDialogAddUser',null,{root:true})
    },
    async closeDialogActivateBill({commit}){
        commit('Users/openDialogAddUser',null,{root:true})
         commit('closeDialogActivateBill')
         commit('setDropdownBills',[])
         commit('updateDropdownBill',null)
    } 
}

const mutations ={
    async setDropdownInactiveBills(state,value){
        let x=value.filter(val=>val.remain_number>0)
        x=x.map((val)=>{
            return {text:val.bill_id,value:{remainNumber:val.remain_number,billID:val.bill_id}}
        })
        state.dropdownBills=x
    },
    async setBillingTable(state, value){
        state.billingTable.billings = value
    },
    async setDropdownBills(state,value){
        let x=value.filter(val=>val.remain_number>0)
        x=x.map((val)=>{
            return val.bill_id
        })
        state.dropdownBills=x
    },
    async updateDropdownBill(state,value){
        state.dropdownBill=value
    },
    async closeTableLoader(state){
        state.billingTable.loading = false
    },
    async showTableLoader(state){
        state.billingTable.loading = true
    },
    async setTableSearchTerm(state, value){
        state.billingTable.search = value
    },
    async openDialogActivateBill(state){
        state.dialogActivateBill=true
    },
    async closeDialogActivateBill(state){
        state.dialogActivateBill=false
    },
}


export default {
    namespaced:true,
    state,
    getters,
    actions,
    mutations
}