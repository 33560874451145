import axios from '../../mixins/axiosInstance'
import CryptoJS from "crypto-js"
const haveText = (param, value) => {
    if (value || value == 0) {
        return '&' + param + '=' + value
    } else {
        return ''
    }

}
const state = () => ({
    ListOfUsers: [],
    LisOfUsersCopi: [],
    dropdownListOfUsers: [],
    ListOfUsersByGroup: [],
    selected: '',

    dialogEditUser: false,
    dialogAddUser: false,
    dialogPauseUser: false,
    dialogActiveUser: false,
    dialogDeleteUser: false,

    password: '',
    hex_sha1_pass: '',
    defaultGroup: '',
    listGroups: '',

    dropdownDefaultGroup: null,
    dropdownListGroups: null,
    valueOfPrivileges: 0,
    privilegesOfUser: [],
    privilegeList: [
        { text: 'Disable text', value: 134217728 },
        { text: 'Stun protection', value: 16777216 },
        { text: 'Management group', value: 8388608 },
        { text: 'Dispatcher account', value: 4194304 },
        { text: 'Last group', value: 2097152 },
        { text: 'Recieve S.O.S', value: 65536 },
        { text: 'Only listen', value: 32768 },
        { text: 'Video', value: 16384 },
        { text: 'View location', value: 4096 },
        { text: 'GPS Positioning', value: 2048 },
        { text: 'Platform Audio', value: 1024 },
        { text: 'Stun', value: 512 },
        { text: 'All call', value: 256 },
        { text: 'Display group members', value: 128 },
        { text: 'Monitor', value: 64 },
        { text: 'Call logs', value: 16 },
        { text: 'Do not disturb if private call', value: 8 },
        { text: 'Change group', value: 4 },
        { text: 'Private call', value: 2 },
        { text: 'Contact', value: 1 },
        { text: 'Prohibit video capture', value: 0 },
        { text: 'Prohibit video monitor', value: 0 }
    ],
    userTypes: [
        { value: 0, icon: 'mdi-account', text: 'General User' },
        { value: 1, icon: 'mdi-cellphone', text: 'Cell Phone' },
        { value: 2, icon: 'mdi-radio-handheld', text: 'Interphone' },
        { value: 3, icon: 'mdi-cellphone-dock', text: 'Law Enforcement Recorder' },
        { value: 4, icon: 'mdi-camera', text: 'Camera' },
        { value: 5, icon: 'mdi-account-star', text: 'Dispatcher' },
        { value: 6, icon: 'mdi-police-badge-outline', text: 'Police' },
        { value: 7, icon: 'mdi-doctor', text: 'Doctor' },
        { value: 8, icon: 'mdi-truck-outline', text: 'Truck' },
        { value: 9, icon: 'mdi-taxi', text: 'Taxi' },
    ],
    selectedUserType: '',
    searchBar: ''
})

const getters = {

}

const actions = {
    async filterList({ commit, state }, value) {
        commit('updateSearchBar', value)
        if (value) {
            commit('setUsers', state.LisOfUsersCopi.filter((x) => {
                if (x.user_name.toUpperCase().includes(value.toUpperCase()) || x.user_account.toUpperCase().includes(value.toUpperCase())) {
                    return x
                }
            }))
        } else {
            commit('setUsers', state.LisOfUsersCopi)
        }


    },
    async getUserByGroup({ commit, dispatch }, value) {
        try {
            let response = await axios.instance.get('/user', {
                params: {
                    method: 'get',
                    group_id: value
                }
            });
            let final_data = [];
            response.data.data.users.forEach(element => {
                final_data.push({
                    text: element.user_name,
                    value: element.user_id
                })
            });
            commit('setUsersByGroup', final_data)
        } catch (error) {
            if (error) {
                dispatch('Snackbars/setSnackbars', {
                    type: 'error',
                    text: 'Ocurrió un error al actualizar el grupo',
                }, { root: true })
            }
        }
    },
    async deleteUser({ commit, dispatch, state }) {
        await axios.instance.get('/user?method=delete&user_ids=' + state.selected.user_id)
        commit('closeDialogDeleteUser')
        dispatch('getUsers')
        commit('setSelected', '')
    },
    async getUsers({commit}) {

        let response = await axios.instance.get('/user?method=get&limit=100&order_by=bill_end_time')
        if (response.data.data.total > 100) {
            try {
                let length = response.data.data.total / 100
                if (length > Math.round(length)) {
                    length = Math.round(length) + 1
                } else {
                    length = Math.round(length)
                }
                let arr = [];
                for (let index = 0; index < length; index++) {
                    let response2 = await axios.instance.get('/user?method=get&limit=100&page=' + index+'&order_by=bill_end_time')
                    arr = arr.concat(response2.data.data.users)

                }
                let dateNow = new Date()
                let countToExpireBill = 0
                arr.filter((x)=>{
                    let y =new Date(x.bill_end_time)
                    let diff =parseInt((y-dateNow)/(24*3600*1000));
                    console.log(diff)
                    if(diff<=15){
                        countToExpireBill++
                    }
                })
                await commit('Dashboard/setCountToExpireBill',countToExpireBill,{root:true})

                // let x=new Date('2021-12-16')
                // console.log(x.getFullYear(),x.getMonth()+1,x.getDate()+1)
                await commit('setUsers', arr)
                await commit('updateLisOfUsersCopi', arr)
                await commit('setDropdownUsersList', arr);
            } catch (error) {
                console.log(error)
            }
        } else {
            await commit('setUsers', response.data.data.users)
            await commit('updateLisOfUsersCopi', response.data.data.users)
            await commit('setDropdownUsersList', response.data.data.users);
        }

    },

    async setSelectedUser({ commit, state }, value) {
        commit('setSelected', value)
        commit('updateSelectedUserType', state.userTypes.filter((x) => {
            if (x.value == value.client_type) {
                return x
            }
        })[0])
        let valueSelected = state.selected.privilege
        let listOfPrivileges = state.privilegeList.reduce((acc, el) => {
            if (valueSelected >= el.value) {
                valueSelected = valueSelected - el.value
                acc.push(el)
            }
            return acc
        }, [])
        commit('updatePriority', state.selected.priority + 1)
        commit('setPrivilegesOfUserToUpdate', listOfPrivileges)
        commit('setValueOfPrivileges')
    },
    async addUser({ dispatch, state, rootState, commit }) {
        try {
            var url = haveText('user_account', state.selected.user_account) +
                haveText('pwd', state.hex_sha1_pass) +
                haveText('user_name', state.selected.user_name) +
                haveText('bill_id', rootState.Billing.dropdownBill) +
                haveText('group_ids', rootState.Groups.groupsWithComas) +
                haveText('default_group', rootState.Groups.dropdownDefaultGroup) +
                haveText('tel', state.selected.tel_number) +
                haveText('privilege', state.valueOfPrivileges) +
                haveText('priority', state.selected.priority - 1) +
                haveText('imei', state.selected.imei) +
                haveText('desc', state.selected.desc)
            await axios.instance.get('/user?method=add&' + url)
            dispatch('closeDialogAddUser')
            commit('setSelected', '')
            dispatch('getUsers')
        } catch (error) {
            await dispatch('Snackbars/setSnackbars', {
                type: 'error',
                text: 'Ocurrió un error al agregar un usuario',
            }, { root: true })
        }
    },
    async updateUser({ state, rootState, dispatch }) {
        var url = haveText('user_account', state.selected.user_account) +
            haveText('pwd', state.hex_sha1_pass) +
            haveText('user_name', state.selected.user_name) +
            haveText('bill_id', state.selected.bill_id) +
            haveText('group_ids', rootState.Groups.groupsWithComas) +
            haveText('default_group', rootState.Groups.dropdownDefaultGroup) +
            haveText('tel', state.selected.tel_number) +
            haveText('privilege', state.valueOfPrivileges) +
            haveText('priority', state.selected.priority - 1) +
            haveText('imei', state.selected.imei) +
            haveText('client_type', state.selectedUserType.value) +
            haveText('desc', state.selected.desc)
        await axios.instance.get(`/user?method=update&` + url.substr(1))
        dispatch('closeDialogEditUser')
        dispatch('getUsers')

    },
    async pauseUser({ state, dispatch, commit }) {
        await axios.instance.get('/user?method=pause&user_ids=' + state.selected.user_id)
        dispatch('getUsers')
        commit('closeDialogPauseUser')
        commit('setSelected', '')
    },
    async activeUser({ state, dispatch, commit }) {
        await axios.instance.get('/user?method=active&user_ids=' + state.selected.user_id)
        dispatch('getUsers')
        commit('closeDialogActiveUser')
        commit('setSelected', '')
    },
    async openDialogEditUser({ state, commit, dispatch }) {
        commit('openDialogEditUser')
        dispatch('Groups/getDropdownGroups', null, { root: true })
        commit('Groups/updateDropdownDefaultGroup', state.selected.default_group, { root: true })
        commit('Groups/updateDropdownGroup', state.selected.group_ids.split(','), { root: true })
    },
    async closeDialogEditUser({ commit }) {
        commit('closeDialogEditUser')
        commit('setSelected', '')
    },
    async openDialogAddUser({ commit, dispatch }) {
        commit('setSelected', { user_account: '', user_name: '', tel_number: '', imei: '' })
        dispatch('Billing/getDropdownBills', null, { root: true })
        commit('openDialogAddUser')
        commit('updateUserAccount', '')
        commit('setPrivilegesOfUser', [])
        commit('updateUsername', null)
        commit('updatePhoneNumber', '')
        commit('updateIMEI', null)
        commit('updateDescription', null)
        commit('updatePriority', 9)
        commit('updatePassword', '')
    },
    async closeDialogAddUser({ commit }) {
        commit('closeDialogAddUser')
        commit('setSelected', '')
    },
    async updatePassword({ commit }, value) {
        let clave = await CryptoJS.SHA1(value)
        clave = await CryptoJS.enc.Hex.stringify(await clave)
        commit('updatePassword', { normal: value, sha1: clave })
    }
}

const mutations = {
    async updateSearchBar(state, value) {
        state.searchBar = value
    },
    async updateLisOfUsersCopi(state, value) {
        state.LisOfUsersCopi = value
    },
    async setDropdownUsersList(state, value) {
        let final_data = [];
        value.forEach(element => {
            final_data.push({
                text: element.user_name,
                value: element.user_id
            })
        });
        state.dropdownListOfUsers = final_data
    },
    async setUsersByGroup(state, value) {
        state.ListOfUsersByGroup = value
    },
    async setUsers(state, value) {
        state.ListOfUsers = value
    },
    async setSelected(state, value) {
        state.selected = value
        state.selected.group_names = state.selected.group_names.split(',')
    },
    async setValueOfPrivileges(state) {

        var el = state.privilegesOfUser.reduce((acc, el) => {
            acc = acc + el.value
            return acc
        }, 0)
        state.valueOfPrivileges = el
    },
    async setPrivilegesOfUserToUpdate(state, value) {
        state.privilegesOfUser = value
    },
    async setPrivilegesOfUser(state, value) {
        state.privilegesOfUser = value
        var el = value.reduce((acc, el) => {
            acc = acc + el
            return acc
        }, 0)
        state.valueOfPrivileges = el
    },
    async updateUsername(state, value) {
        state.selected.user_name = value
    },
    async updatePassword(state, value) {
        state.password = value.normal
        state.hex_sha1_pass = value.sha1
    },
    async updatePhoneNumber(state, value) {

        state.selected.tel_number = value
    },
    async updateIMEI(state, value) {
        state.selected.imei = value
    },
    async updateDescription(state, value) {
        state.selected.desc = value
    },
    async updatePriority(state, value) {
        state.selected.priority = value
    },
    async updateUserAccount(state, value) {
        state.selected.user_account = value
    },
    async updateSelectedUserType(state, value) {
        state.selectedUserType = value
    },
    async openDialogEditUser(state) {
        state.dialogEditUser = true
    },
    async closeDialogEditUser(state) {
        state.dialogEditUser = false
    },
    async openDialogAddUser(state) {
        state.dialogAddUser = true
    },
    async closeDialogAddUser(state) {
        state.dialogAddUser = false
    },
    async openDialogPauseUser(state) {
        state.dialogPauseUser = true
    },
    async closeDialogPauseUser(state) {
        state.dialogPauseUser = false
    },
    async openDialogActiveUser(state) {
        state.dialogActiveUser = true
    },
    async closeDialogActiveUser(state) {
        state.dialogActiveUser = false
    },
    async openDialogDeleteUser(state) {
        state.dialogDeleteUser = true
    },
    async closeDialogDeleteUser(state) {
        state.dialogDeleteUser = false
    }
}


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}