import axios from '../mixins/axiosInstance.js';

export default {
    async verifyAuth() {
        
        if(!localStorage.isLogged){
            localStorage.setItem('isLogged',false)
        }
        let response = await axios.instance.get('/user?method=get',{ withCredentials: true, crossDomain: true })
        if(localStorage.isLogged==='false'){
            return false
        }
        if(response.data.msg === 'notLoggedIn'){
            return false
        }
        else{
            return true
        }
    }
}