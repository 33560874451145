import axios from '../../mixins/axiosInstance'
import CryptoJS from "crypto-js" 

const state = () => ({
    account: '',
    password: '',
    isVisiblePassword: false,
    containerStyle: '',
    random:''
})

const getters = {}

const actions = {

  async getRandom({commit})
  {
    let data=await axios.instance.get('/random',{withCredentials: true, crossDomain: true})
    commit('setRandomValue',data.data.data.random)
  },
  async login({ state, dispatch }){
      
      let clave = await CryptoJS.SHA1(state.password)
      clave=await CryptoJS.enc.Hex.stringify(await clave)
      let encrypted = await CryptoJS.HmacSHA1(clave,state.random);
      encrypted = await CryptoJS.enc.Hex.stringify(await encrypted);
      let login=await axios.instance.get('/organization?method=login&account='+state.account+'&pwd='+encrypted+'&timeZoneOffset=-360', { withCredentials: true, crossDomain: true })
      if(login.data.msg === 'success'){
        localStorage.setItem('username', state.account);
        localStorage.isLogged=true
        window.location.reload();
      }
      else{
        dispatch('Snackbars/setSnackbars', {
          type:'error',
          text: 'Usuario o contraseña incorrectos',
        }, { root: true })
      }
  },
}

const mutations = {
  setRandomValue(state,value){
    state.random=value
  },
  updatePassword(state,value){
    state.password=value
  },
  updateAccount(state,value){
    state.account=value
  },
  updateIsVisiblePassword(state){
    state.isVisiblePassword=!state.isVisiblePassword
  },
  reziseBackground(state){
    let padding_top
    if(window.innerHeight<900)
    {
      padding_top=window.innerHeight/20
    }else{
      padding_top=window.innerHeight/5
    }
    state.containerStyle = 'padding-top: '+padding_top+'px; height: '+window.innerHeight +'px; width: '+window.innerWidth+'px;'
  },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}