import axios from '../../mixins/axiosInstance.js';

const state = () => ({
    search: '',
    PageLength: 0,
    Page: 1,
    dropdownGroups: [],
    dropdownGroup: null,
    dropdownDefaultGroup: null,
    groupsWithComas: '',
    ListOfGroups: [],
    LisOfGroupsCopi: [],
    selected: null,
    searchBar: '',
    FormValues: {
        show: false,
        group_name: '',
        contact_name: '',
        contact_phone: '',
        show_user: 0,
    },
    updateDialog: false,
    deleteDialog: false,
    groupUsers: []
})

const getters = {}

const actions = {
    async changePage({ dispatch, commit, state }, value) {
        await commit('changePage', value)
        await dispatch('getTableGroups')
        await dispatch('filterList', state.searchBar)
    },
    async nextPage({ state, dispatch }) {
        await dispatch('getTableGroups')
        await dispatch('filterList', state.searchBar)
    },
    async previusPage({ commit, state, dispatch }) {
        await commit('changePage', state.Page - 1)
        await dispatch('getTableGroups')
        await dispatch('filterList', state.searchBar)
    },
    async filterList({ commit, state }, value) {
        commit('updateSearchBar', value)
        if (value) {
            commit('setGroupTable', state.LisOfGroupsCopi.filter((x) => {

                if (x.group_name.toUpperCase().includes(value.toUpperCase())) {
                    return x
                }

            }))
        } else {
            commit('setGroupTable', state.LisOfGroupsCopi)
        }
    },
    async addGroup({ commit, dispatch, state }) {
        try {
            if (state.FormValues.group_name && state.FormValues.contact_name) {
                await axios.instance.get('/group', {
                    params: {
                        method: 'add',
                        group_name: state.FormValues.group_name,
                        contact_name: state.FormValues.contact_name,
                        contact_phone: state.FormValues.contact_phone,
                        show_user: state.FormValues.show_user,
                        priority: state.FormValues.priority
                    }
                });
                commit('closeGroupForm');
                dispatch('getTableGroups');
                dispatch('Snackbars/setSnackbars', {
                    type: 'success',
                    text: 'Se agregó el grupo correctamente',
                }, { root: true })
            }
            else {
                dispatch('Snackbars/setSnackbars', {
                    type: 'warning',
                    text: 'Llene los campos obligatorios para continuar',
                }, { root: true })
            }
        } catch (error) {
            if (error) {
                dispatch('Snackbars/setSnackbars', {
                    type: 'error',
                    text: 'Ocurrió un error al crear un nuevo grupo',
                }, { root: true })
            }
        }
    },
    async getTableGroups({ commit, dispatch }) {
        try {
            let data = await axios.instance.get('/group?method=get&limit=100')
            if (data.data.data.total > 100) {

                var length = data.data.data.total / 100
                if (length > Math.round(length)) {
                    length = Math.round(length) + 1
                } else {
                    length = Math.round(length)
                }
                var arr = [];
                for (let index = 0; index < length; index++) {
                    let response2 = await axios.instance.get('/group?method=get&limit=100&page=' + index)
                    arr = arr.concat(response2.data.data.groups)

                }
                commit('setGroupTable', arr);
                commit('updateLisOfGroupsCopi', arr)
            } else {
                commit('setGroupTable', data.data.data.groups);
                commit('updateLisOfGroupsCopi', data.data.data.groups)
            }

        } catch (error) {
            if (error) {
                dispatch('Snackbars/setSnackbars', {
                    type: 'error',
                    text: 'Ocurrió un error al obtener los grupos',
                }, { root: true })
            }
        }
    },
    async getDropdownGroups({ commit }) {
        let data = await axios.instance.get('/group?method=get&limit=100')
        commit('setDropdownGroups', data.data.data.groups)
    },

    async setSelectedGroup({ commit, dispatch }, value) {
        commit('setSelectedGroup', value)
        dispatch('Users/getUserByGroup', value.group_id, { root: true })
    },
    async updateGroup({ commit, dispatch, state, rootState }) {
        try {
            await commit('closeUpdateGroupForm');
            await commit('Loader/showLoader', null, { root: true })
            if (state.selected.group_name && state.selected.contact_name) {
                await axios.instance.get('/group', {
                    params: {
                        method: 'update',
                        group_id: state.selected.group_id,
                        group_name: state.selected.group_name,
                        contact_name: state.selected.contact_name,
                        contact_phone: state.selected.contact_phone,
                        show_user: state.selected.show_user,
                        priority: state.selected.priority
                    }
                });
                if (rootState.Users.ListOfUsersByGroup) {
                    rootState.Users.ListOfUsersByGroup.forEach(async element => {
                        console.log(element)
                        await axios.instance.get('/groupuser', {
                            params: {
                                method: 'add',
                                group_id: state.selected.group_id,
                                user_ids: element
                            }
                        });
                    });
                }
                await commit('restartSelected');
                await dispatch('getTableGroups');
                await dispatch('Snackbars/setSnackbars', {
                    type: 'success',
                    text: 'Se actualizó el grupo correctamente',
                }, { root: true })
            }
            else {
                await dispatch('Snackbars/setSnackbars', {
                    type: 'warning',
                    text: 'Llene los campos obligatorios para continuar',
                }, { root: true })
            }
            await commit('Loader/closeLoader', null, { root: true })
        } catch (error) {
            if (error) {
                await dispatch('Snackbars/setSnackbars', {
                    type: 'error',
                    text: 'Ocurrió un error al actualizar el grupo',
                }, { root: true })
            }
        }
    },
    async deleteGroup({ commit, dispatch, state }) {
        try {
            await axios.instance.get('/group', {
                params: {
                    method: 'delete',
                    group_ids: state.selected.group_id,
                }
            });
            commit('closeDeleteGroupForm');
            dispatch('getTableGroups');
            dispatch('Snackbars/setSnackbars', {
                type: 'success',
                text: 'Se eliminó el grupo correctamente',
            }, { root: true })
        } catch (error) {
            if (error) {
                dispatch('Snackbars/setSnackbars', {
                    type: 'error',
                    text: 'Ocurrió un error al actualizar el grupo',
                }, { root: true })
            }
        }
    }
}

const mutations = {
    async updatePagesLength(state, value) {
        if (value > Math.round(value)) {
            state.PageLength = Math.round(value) + 1
        } else {
            state.PageLength = Math.round(value)
        }
    },
    async changePage(state, value) {
        state.Page = value
    },
    async updateLisOfGroupsCopi(state, value) {
        state.LisOfGroupsCopi = value
    },
    async updateSearchBar(state, value) {
        state.searchBar = value
    },
    async setGroupUsers(state, value) {
        state.groupUsers = value
    },
    async setPriority(state, value) {
        state.FormValues.priority = value
    },
    async setGroupName(state, value) {
        state.FormValues.group_name = value
    },
    async setContactName(state, value) {
        state.FormValues.contact_name = value
    },
    async setContactPhone(state, value) {
        state.FormValues.contact_phone = value
    },
    async setShowUser(state, value) {
        state.FormValues.show_user = value
    },
    async setGroupTable(state, value) {
        state.ListOfGroups = value
    },
    async setSelectedGroup(state, value) {
        state.selected = value
    },
    async setTableSearchTerm(state, value) {
        state.search = value
    },
    async updateDropdownGroup(state, value) {
        state.groupsWithComas = value.reduce((acc, el) => {
            if (acc) {
                acc = acc + ',' + el
            } else {
                acc = el
            }
            return acc
        }, '')

        state.dropdownGroup = value.map((x) => {
            return x = parseInt(x)
        })

    },
    async updateOneDropdownGroup(state, value) {
        console.log(value)
        state.dropdownGroup = value
    },
    async updateDropdownDefaultGroup(state, value) {
        state.dropdownDefaultGroup = value
    },
    async closeGroupForm(state) {
        state.FormValues.show = false
    },
    async showGroupForm(state) {
        state.FormValues.show = true
    },
    async setDropdownGroups(state, value) {
        state.dropdownGroups = value.map((x) => {
            return { text: x.group_name, value: x.group_id }
        })
    },
    async setSelectedPriority(state, value) {
        state.selected.priority = value
    },
    async setSelectedGroupName(state, value) {
        state.selected.group_name = value
    },
    async setSelectedContactName(state, value) {
        state.selected.contact_name = value
    },
    async setSelectedContactPhone(state, value) {
        state.selected.contact_phone = value
    },
    async setSelectedShowUser(state, value) {
        state.selected.show_user = value
    },
    async restartSelected(state) {
        state.selected = null
    },
    async closeUpdateGroupForm(state) {
        state.updateDialog = false
    },
    async showUpdateGroupForm(state) {
        state.updateDialog = true
    },
    async showDeleteGroupForm(state) {
        state.deleteDialog = true;
    },
    async closeDeleteGroupForm(state) {
        state.deleteDialog = false;
    }
}


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}