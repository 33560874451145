import Vue from 'vue'
import Vuex from 'vuex'
import Login from './modules/login'
import Loader from './modules/Loader'
import Snackbars from './modules/Snackbars';
import Navbar from './modules/Navbar'
import Users from './modules/Users'
import Billing from './modules/Billing'
import Groups from './modules/Groups'
import Maps from './modules/Maps'
import Dashboard from './modules/dashboard'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
    modules: {
        Login,
        Loader,
        Snackbars,
        Navbar,
        Users,
        Billing,
        Groups,
        Maps,
        Dashboard
    },
    strict: debug,
  
})