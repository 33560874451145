import axios from '../../mixins/axiosInstance'
import qs from 'qs';

const state = () => ({
    navbarMenu: {
        menu: [
            { id: "1", title: "Dashboard", icon: "mdi-speedometer", link: "/" },
            { id: "2", title: "Usuarios", icon: "mdi-account-circle-outline", link: "/Users" },
            { id: "3", title: "Licencias", icon: "mdi-card-account-details-outline", link: "/Billing" },
            { id: "4", title: "Grupos", icon: "mdi-account-group-outline", link: "/Groups", },
            { id: "5", title: "Mapa", icon: "mdi-map-marker-radius", link: "/Maps" }
        ],
        drawer: false,
        group: null,
        tab: null,
        username: '',
        email: '',
        initial: ''
    },
    manageSettingsDialog:{
        dialog: false,
        oldPassword: '',
        newPassword: '',
        passwordRules: [
            value => !!value || 'Required.',
            v => v.length >= 8 || 'Min 8 characters'
        ],
    },
    accountNotifications: {
        headNotifications: [],
        allNotifications: [],
        notificationsCount: 0,
    },
})
const getters = {}

const actions = {
    async changePassword({ state }, data){
        axios.instance.defaults.headers.common['token'] = localStorage.getItem("token");
        await axios.instance.patch('/User?email='+state.email, qs.stringify(data))
        .then(function(response){
            if(response.data.data){
                if(!alert(response.data.data)){
                    window.location.reload()
                }
            }
        }).catch(function(error){
            if(error.response.data.error){
                alert(error.response.data.error)
            }
            else{
                alert('Error has occurred')
            }
        })
    },
    async getNotificationsCount({ commit }){
        axios.instance.defaults.headers.common['token'] = localStorage.getItem('token');
        axios.instance.get('/notifications?count=true')
        .then(function(response){
            commit('setCount', response.data.data[0].count)
        }).catch(function(error){
            console.log(error)
        })
    },
    async getAllNotifications({ commit,state }){
        axios.instance.defaults.headers.common['token'] = localStorage.getItem('token');
        axios.instance.get('/notifications')
        .then(function(response){
            if(state.notificationsCount > 4){
                commit('setHeadNotifications', response.data.data.slice(0,4));
                commit('setAllNotifications', response.data.data);
            }
            else{
                commit('setAllNotifications', response.data.data);
                commit('setHeadNotifications', response.data.data);
            }

        })
    },
    async setUserAccountInformation({commit}, value){
        try {
            if(value){
                commit('setNavbarMenuUsername', value);
                commit('setNavbarMenuUsernameInitial',value.charAt(0).toUpperCase())
            }
        } catch (error) {
            if(error){
                alert('No se encontró información de la cuenta del usuario')
            }
        }
    }
}
const mutations = {
    showManageSettingsDialog(state) {
        state.manageSettingsDialog.dialog = true;
    },
    closeManageSettingsDialog(state){
        state.manageSettingsDialog.dialog = false;
    },
    navigationDrawerStateModifier(state, value){
        state.navbarMenu.drawer = value
    },
    setMenu(state) {
        axios.instance.defaults.cancelToken
        if (localStorage.isAdmin == 0) {
            var filtered = state.navbarMenu.menu.filter(function (value) {
                return value.isAdmin === 0;
            });
            state.navbarMenu.menu = filtered
        }
    },
    async setDataToManageSettingsDialog(state,data){
        state.name = data.name;
        state.email = data.email;
    },
    setNotificationsCount(state, value){
        state.accountNotifications.notificationsCount = value;
    },
    setAllNotifications(state, value){
        state.accountNotifications.allNotifications = value
    },
    setHeadNotifications(state, value){
        state.accountNotifications.headNotifications = value;
    },
    setNavbarMenuTab(state, value){
        state.navbarMenu.tab = value
    },
    setNavbarMenuGroup(state,value){
        state.navbarMenu.group = value
    },
    setNavbarMenuUsername(state, value){
        state.navbarMenu.username = value
    },
    setNavbarMenuUsernameInitial(state, value){
        state.navbarMenu.initial = value
    },

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}