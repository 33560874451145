import axios from '../../mixins/axiosInstance.js';
import randomColor from 'randomcolor';

var getDates = function(startDate, endDate) {
    var dates = [],
        currentDate = startDate,
        addDays = function(days) {
          var date = new Date(this.valueOf());
          date.setDate(date.getDate() + days);
          return date;
        };
    while (currentDate <= endDate) {
      dates.push(currentDate);
      currentDate = addDays.call(currentDate, 1);
    }
    return dates;
};

const state = () => ({
    countToExpireBill:0,
    totalGroupsPtt:[],
    statistics: {},
    userAudioStatistics: [],
    groupAudioStatistics: [],
    dates: [],
    showGraph: false,
    groups: {
        labels: [],
        datasets: [{
            backgroundColor: [],
            data: []
        }]
    },
    groupIds: [],
    topPTTUsers: [],
    dashboardUserTable: {
        loading: true,
        itemKey: 'userAccount',
        classProp: 'elevation-1',
        loadingText: "Cargando...",
        noDataText: "No hay registros disponibles",
        dense: true,
        headers: [
            {
                text: "Nombre de usuario",
                sortable: false,
                value: "userName",
            },
            {
                text: "Cuenta",
                sortable: true,
                value: "userAccount",
            },
            {
                text: "Número de PTTs",
                sortable: true,
                value: "callTimes",
            },
            {
                text: "Duración de la llamada",
                sortable: true,
                value: "callDuration",
            },
            {
                text: "Fecha",
                sortable: true,
                value: "date",
            }
        ],
    },
    dashboardGroupTable: {
        loading: true,
        itemKey: 'groupId',
        classProp: 'elevation-1',
        loadingText: "Cargando...",
        noDataText: "No hay registros disponibles",
        dense: true,
        headers: [
            {
                text: "ID",
                sortable: false,
                value: "groupId",
            },
            {
                text: "Nombre del grupo",
                sortable: true,
                value: "groupName",
            },
            {
                text: "Duración de la llamada",
                sortable: true,
                value: "callDuration",
            },
            {
                text: "Número de PTTs",
                sortable: true,
                value: "callTimes",
            },
            {
                text: "Fecha",
                sortable: true,
                value: "date",
            }
        ],
    },
    dashboardGroupTableTotal: {
        loading: true,
        itemKey: 'groupId',
        classProp: 'elevation-1',
        loadingText: "Cargando...",
        noDataText: "No hay registros disponibles",
        dense: true,
        headers: [
            {
                text: "ID",
                sortable: false,
                value: "groupId",
            },
            {
                text: "Nombre del grupo",
                sortable: true,
                value: "groupName",
            },
            {
                text: "Número de PTTs",
                sortable: true,
                value: "callTimes",
            }
        ],
    },
})

const getters = {
    groups: state => {
        return state.groups
    }
}

const actions = {
    async getStatistics({ commit, dispatch, state, rootState }){
        try {
            let data = await axios.instance.get('/statistics')
            commit('setStatistics', data.data.data.info);
            let audioStatisticsArrayRequests = []
            //let audioStatisticsPerUser = []
            var date = new Date();
            date.setDate(date.getDate() - 2)
            commit('setDatesToState', getDates(date, new Date()))
            await dispatch('Groups/getTableGroups', null, { root: true })
            state.dates.forEach(element => {
                let currentdate = new Date(element);
                let formatedCurrentDate = (currentdate.getFullYear()+'-'+(currentdate.getMonth()+1)+'-'+currentdate.getDate())
                audioStatisticsArrayRequests.push('/audioStatistics?method=getAll&page=1&time='+formatedCurrentDate)
            });
            let promises = audioStatisticsArrayRequests.map(async url => await axios.instance.get(url))
            Promise.all(promises).then(responses => responses.forEach(
                response => {
                    if(response.data.data.audioStatisticsList.length > 0){
                        response.data.data.audioStatisticsList.forEach(element2 => {
                            commit('setGroupAudioStatistics',{ callDuration: element2.callDuration, callTimes: element2.callTimes, groupId: element2.groupId, groupName: element2.groupName, date: response.config.url.match(/[0-9]*-[0-9]*-[0-9]*/)[0] });
                        })
                    }
                } 
            )).then(() => {
                var countAll=rootState.Groups.ListOfGroups.reduce((arr,el)=>{
                    var accumulator = arr
                    accumulator.push(state.groupAudioStatistics.filter((el2)=>{
                        return el2.groupId===el.group_id
                    }))
    
                    return arr
                },[])
                var countAll2= countAll.reduce((arr,el)=>{
                    arr.push(el.reduce((arr,el)=>{
                        let sum
                        if(!arr.callTimes){
                            sum = el.callTimes
                        }
                        else{
                            sum = arr.callTimes+el.callTimes
                        }
                        arr={callTimes:sum,groupName:el.groupName,groupId: el.groupId}
                        return arr
                    },{}))
                    return arr
                },[])
                let sortedCountAll2 = countAll2.sort(function(a,b){
                    if(a.callTimes < b.callTimes){
                        return 1
                    }
                    if(a.callTimes > b.callTimes){
                        return -1
                    }
                    return 0
                })
                let a = sortedCountAll2.filter(value => Object.keys(value).length !== 0).slice(0,4)
                let callTimesData = []
                let labels = []
                let backgroundColors = []

                a.forEach(element => {
                    callTimesData.push(element.callTimes)
                    labels.push(element.groupName)
                    backgroundColors.push(randomColor())
                })
                commit('setTotalGroupsPTTs',sortedCountAll2.filter(value => Object.keys(value).length !== 0))
                commit('setGroupGraph', {
                    data: callTimesData,
                    labels: labels,
                    backgroundColor: backgroundColors
                });
                commit('closeGroupTableLoader')
                /*console.log(countAll2)

                state.dates.forEach(element => {
                    let currentdate = new Date(element);
                    let formatedCurrentDate = (currentdate.getFullYear()+'-'+(currentdate.getMonth()+1)+'-'+currentdate.getDate())
                    countAll2.forEach(element => {
                        audioStatisticsPerUser.push('/audioStatistics?method=getByGroupId&page=1&time='+formatedCurrentDate+'&group_id='+element.groupId)
                    })
                });*/
                /*console.log(audioStatisticsPerUser)
                let promises = audioStatisticsPerUser.map(async url => await axios.instance.get(url))
                Promise.all(promises).then(responses => responses.forEach(response => {
                    if(response.data.data.audioStatisticsList.length > 0){
                        response.data.data.audioStatisticsList.forEach(element2 => {
                            commit('setGroupAudioStatistics',{ callDuration: element2.callDuration, callTimes: element2.callTimes, userAccount: element2.userAccount, userName: element2.userName, date: response.config.url.match(/[0-9]*-[0-9]*-[0-9]/)[0] });
                        })
                    }
                })).then(() => commit('closeUserTableLoader'))*/
            })
            
        } catch (error) {
            if(error){
                dispatch('Snackbars/setSnackbars', {
                    type: 'error',
                    text: 'Ocurrió un error al obtener las estadísticas',
                }, { root: true })
            }
        }
    }

/**
 * state.dates.forEach(async element3 => {
                                let currentdate = new Date(element3)
                                let formatedCurrentDate = (currentdate.getFullYear()+'-'+(currentdate.getMonth()+1)+'-'+currentdate.getDate())
                                state.groupIds.forEach(async element5 => {
                                    data2 = await axios.instance.get('/audioStatistics?method=getByGroupId&page=1&time='+formatedCurrentDate+'&group_id='+element5);
                                    if(data2.data.data.userAudioStatisticsList.length > 0){
                                        data2.data.data.userAudioStatisticsList.forEach(element4 => {
                                            commit('setUserAudioStatistics',{ callDuration: element4.callDuration, callTimes: element4.callTimes, userAccount: element4.userAccount, userName: element4.userName, date: element3 });
                                        })
                                    }
                                })
 */

    /*async getStatistics({ commit, dispatch, state }) {
        try {
            var date = new Date();
            date.setDate(date.getDate() - 30);
            commit('setDatesToState',getDates(date, new Date()))
            let data = await axios.instance.get('/statistics')
            commit('setStatistics', data.data.data.info);
            let x=0;
            state.dates.forEach(element => {
                let currentdate = new Date(element)
                let formatedCurrentDate = (currentdate.getFullYear()+'-'+(currentdate.getMonth()+1)+'-'+currentdate.getDate())
                axios.instance.get('/audioStatistics?method=getAll&page=1&time='+formatedCurrentDate).then(data => {
                    if(data.data.data.audioStatisticsList.length > 0){
                        data.data.data.audioStatisticsList.forEach(element2 => {
                            commit('setGroupAudioStatistics',{ callDuration: element2.callDuration, callTimes: element2.callTimes, groupId: element2.groupId, groupName: element2.groupName, date: element });
                        })
                    }
                    x=x+1;
                if(state.dates.length === x){
                    x=0
                    commit('closeGroupTableLoader')
                    state.groupAudioStatistics.forEach(async element => {
                        x=x+1;
                        if(state.groups.labels.includes(element.groupName)){
                            let index = state.groups.labels.indexOf(element.groupName)
                            let newValue = state.groups.datasets[0].data[index] + element.callTimes;
                            commit('updateGroupsGraphData', {new: newValue, i: index})
                        }
                        else{
                            commit('setNewGroupGraphData', element)
                            commit('updateGroupIdsArray', element.groupId);
                        }
                        if(state.groupAudioStatistics.length === x){
                            console.log(state.groups)
                            commit('setGroupGraph', {
                                data: state.groups.datasets[0].data.slice(0,4),
                                labels: state.groups.labels.slice(0,4),
                                backgroundColor: state.groups.datasets[0].backgroundColor.slice(0,4)
                            });
                            let data2;
                            let y=0;
                            state.dates.forEach(async element3 => {
                                let currentdate = new Date(element3)
                                let formatedCurrentDate = (currentdate.getFullYear()+'-'+(currentdate.getMonth()+1)+'-'+currentdate.getDate())
                                state.groupIds.forEach(async element5 => {
                                    data2 = await axios.instance.get('/audioStatistics?method=getByGroupId&page=1&time='+formatedCurrentDate+'&group_id='+element5);
                                    if(data2.data.data.userAudioStatisticsList.length > 0){
                                        data2.data.data.userAudioStatisticsList.forEach(element4 => {
                                            commit('setUserAudioStatistics',{ callDuration: element4.callDuration, callTimes: element4.callTimes, userAccount: element4.userAccount, userName: element4.userName, date: element3 });
                                        })
                                    }
                                })
                                y=y+1
                                if(state.dates.length === y){
                                    y=0
                                    commit('closeUserTableLoader')
                                    
                                }
                            });
                        }
                    });
                }
                })
            });
            commit('flatArrayDimensionality')
            commit('showGraph');
        } catch (error) {
            if (error) {
                dispatch('Snackbars/setSnackbars', {
                    type: 'error',
                    text: 'Ocurrió un error al obtener las estadísticas'+error,
                }, { root: true })
            }
        }
    }*/
}

const mutations = {
    async setCountToExpireBill(state,value){
        state.countToExpireBill=value
    },
    async setTotalGroupsPTTs(state,value){
        console.log(value)
        state.totalGroupsPtt=value
    },
    async showGraph(state){
        state.showGraph = true
    },
    async setStatistics(state, value) {
        state.statistics = value
    },
    async flatArrayDimensionality(state){
        state.groupAudioStatistics.flat(2);
    },
    async setUserAudioStatistics(state, value) {
        state.userAudioStatistics.push(value)
    },
    async setGroupAudioStatistics(state, value){
        state.groupAudioStatistics.push(value)
    },
    async setDatesToState(state, value){
        state.dates = value
    },
    async setGroupTableSearchTerm(state,value){
        state.dashboardGroupTable.search = value
    },
    async openGroupTableLoader(state){
        state.dashboardGroupTable.loading = true
    },
    async closeGroupTableLoader(state){
        state.dashboardGroupTable.loading = false
        state.dashboardGroupTableTotal.loading = false
    },
    async closeUserTableLoader(state){
        state.dashboardUserTable.loading = false
    },
    async openUserTableLoader(state){
        state.dashboardUserTable.loading = true
    },
    async updateGroupsGraphData(state, value){
        state.groups.datasets[0].data[value.i] = value.new;
    },
    async setNewGroupGraphData(state, value){
        state.groups.labels.push(value.groupName)
        state.groups.datasets[0].backgroundColor.push(randomColor())
        state.groups.datasets[0].data.push(value.callTimes)
    },
    async setGroupGraph(state,value){
        state.groups.datasets[0].data = value.data
        state.groups.labels = value.labels
        state.groups.datasets[0].backgroundColor = value.backgroundColor
    },
    async orderGroupGraphData(state){
        state.groups.datasets[0].data.sort(function(a, b) {
            return b - a;
        });
    },
    async updateGroupIdsArray(state, value){
        state.groupIds.push(value)
    }
}


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}